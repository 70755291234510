import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { newsAndEventDetails as newsAndEventDetailsApi } from "../../../helpers/api";
import { useEffect } from "react";
import InlineLoader from "../../comman/InlineLoader";
import config from "../../../config";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const NewsAndEventDetails = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [newsAndEventsData, setNewsAndEventsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await newsAndEventDetailsApi(decodeURI(params.title));
      setNewsAndEventsData(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const htmlDecode = (content) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
// for lang change
const { t, i18n } = useTranslation();

const changeLanguage = (event) => {
  const selectedLanguage = event.target.value;
  i18n.changeLanguage(selectedLanguage);
}
  return (
    <>
      <CommonMeta
        title="Recent News and Events Updates at Poddar Pigments Ltd"
        description="Stay updated with the latest news and events at Poddar Pigments Ltd. Join us on our journey of innovation and progress."
        keywords="new & events updates, plastic masterbatch manufacturer, masterbatch company in India, color masterbatch manufacturer,"
        name="News & Event"
        type="article"
      />
      <Header />
      <div className="top-banner-image news-bg">
        {/* <Container>
          <Row>
            <div className="top-heading-text">
              <h1> News, Event and Awards</h1>
            </div>
          </Row>
        </Container> */}
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb investorRelations">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/news-and-events"> {t("News And Events")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link> {t(`${params.title}`)}</Link>
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <InlineLoader loading={loading} />
        {!loading && (
          <Row className="news-details">
            <Col lg={2}> </Col>
            <Col lg={8}>
              <h4>{newsAndEventsData?.title}</h4>
              <img
                loading="lazy"
                src={`${config.NEWS_AND_EVENT_IMG_URL}/${newsAndEventsData?.image}`}
                className="img-fluid"
                alt=""
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlDecode(newsAndEventsData?.content),
                }}
                className="SearchResult-body"
              ></div>
            </Col>
            <Col lg={2}> </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
};
export default NewsAndEventDetails;
