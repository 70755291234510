import { Col, Container, Row } from "react-bootstrap"
import Images from "../../../helpers/images"
import { useTranslation } from "react-i18next";
import {
  getTestimonials as getTestimonialsApi,
} from "../../../helpers/api";
import { useEffect, useState } from "react";
import config from "../../../config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { isMobile, isTablet } from "react-device-detect";


const Testimonials = () => {
  // for lang change
  const { t } = useTranslation();
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    getTestimonials();
  }, []);

  const getTestimonials = async () => {
    try {
      const { data } = await getTestimonialsApi();
      setTestimonials(data.result);
    } catch (error) {
    }
  };

  const BigDesktop = window.matchMedia("(min-width: 1500px)").matches;

  return (
    <>
      {testimonials.length > 0 && (
        <div className="why-choose-us-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="heading-text">
                  <h4>{t("testimonials.title")}</h4>
                </div>
              </Col>
            </Row>
            <Row className="testimonials-slider">
              <Col>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={BigDesktop ? 5.5 : (isMobile ? 1.5 : (isTablet ? 3.5 : 4.5))}
                  autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true
                  }}
                  modules={[Autoplay]}
                  loop={true}
                >
                  {testimonials?.map((item, index) => (
                    <SwiperSlide key={`testimonials_${index}`} className="card-box">
                      <div className="card-image">
                        <img loading="lazy"
                          src={`${config.TESTIMONIALS_IMG_URL}/${item.image}`}
                          className='img-fluid' alt='' style={{ borderRadius: '50%', margin: '0 auto' }} />
                      </div>
                      <div className="card-body">
                        <p style={{ marginTop: 10, fontSize: '14px' }}>{item.content}</p>
                        <h5 className="" style={{ marginBottom: 0, fontSize: '20px' }}>{item.name}</h5>
                        <p style={{ margin: 0, fontSize: '14px' }}>{item.designation}</p>
                        <p style={{ margin: 0, fontSize: '14px' }}>{item.company}</p>
                      </div>
                    </SwiperSlide>

                  ))}
                </Swiper>
              </Col>
            </Row>
          </Container>
        </div >

      )}
    </>
  )
}
export default Testimonials